<template>
  <base-layout-two
    page-title="Task"
    page-default-back-link="/home"
    end-button-text="Edit"
    :end-button-url="`/tasks/${$route.params.id}/edit`"
  >
    <section class="ion-padding background-white">
      <ion-item lines="none" class="ion-no-padding">
        <ion-checkbox
          slot="start"
          :checked="task.status"
          @ionChange="handleCheck($event)"
        ></ion-checkbox>
        <ion-label class="ion-text-wrap" :router-link="`/tasks/${task.id}`">
          <div class="h1">
            {{ task.title }}
          </div>
        </ion-label>
      </ion-item>
    </section>

    <div class="padding-x-8 padding-top-16">
      <base-card>
        <user-preview-card
          :user="task.user"
          :router-link="`/users/${task.user_id}`"
          v-if="task.user_id"
        ></user-preview-card>
      </base-card>
    </div>

    <div class="padding-y-8 padding-x-8">
      <base-card>
        <ion-grid class="ion-no-padding">
          <ion-row>
            <ion-col size="12">
              <base-field-display label-text="Description">
                <p>{{ task.description }}</p>
              </base-field-display>
            </ion-col>

            <ion-col size="12" size-lg="6">
              <base-field-display label-text="Due Date">
                <p :class="overdue ? 'danger' : ''">
                  {{ formatDate(task.due_date, "MMM d, yyyy") }}
                  {{ overdue ? "(Overdue)" : "" }}
                </p>
              </base-field-display>
            </ion-col>

            <ion-col size="12" size-lg="6">
              <base-field-display label-text="Assigned to">
                <p v-if="task.assigned_role" class="ion-text-capitalize">
                  {{ assignedRole }}
                </p>
              </base-field-display>
            </ion-col>
          </ion-row>
        </ion-grid>
      </base-card>
    </div>
  </base-layout-two>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonCheckbox,
} from "@ionic/vue";
import { formatDate } from "@/util/helpers";
import UserPreviewCard from "@/components/users/UserPreviewCard.vue";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonCheckbox,
    UserPreviewCard,
  },

  data() {
    return {
      task: {
        user: {},
      },
      formatDate,
    };
  },

  computed: {
    assignedRole() {
      if (this.task.assigned_role) {
        if (this.task.assigned_role === "tasks_manager") return "Management";
        else if (this.task.assigned_role === "tasks_care_associate")
          return "Care Team";
      }

      return "";
    },

    overdue() {
      if (new Date(this.task.due_date) < new Date() && this.task.status === 0) {
        return true;
      }

      return false;
    },
  },

  ionViewWillEnter() {
    this.fetchTask();
  },

  methods: {
    async fetchTask() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/tasks/${this.$route.params.id}`)
        .then((response) => {
          this.task = response.data.task;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async handleCheck(event) {
      if (event.detail.checked) {
        this.task.status = true;
        await this.updateTask();
      } else {
        this.task.status = false;
        await this.updateTask();
      }
    },

    async updateTask() {
      const config = {
        method: "patch",
        url: `${process.env.VUE_APP_API}/tasks/${this.$route.params.id}`,
        data: this.task,
      };

      await this.axios(config)
        .then((response) => {
          this.task = response.data.task;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
ion-checkbox {
  position: relative;
}
</style>