<template>
  <ion-item :lines="lines" class="ion-no-padding">
    <div>
      <span class="h6">
        {{ user.fullname }}
        <ion-chip color="primary" class="ion-text-capitalize">
          {{ user.role }}
        </ion-chip>
      </span>
      <br />
      <span class="p padding-bottom-16" v-if="user.role === 'mother'">{{ user.email }}</span>
    </div>
  </ion-item>
</template>

<script>
import { IonItem, IonChip } from "@ionic/vue";

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    lines: {
      type: String,
      default: "none",
    },
  },

  components: {
    IonItem,
    IonChip
  },

  computed: {},
};
</script>

<style scoped>
ion-item {
  --inner-padding-bottom: 16px;
}
</style>